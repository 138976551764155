<template>
  <div class="">
    <img alt="Vue logo" src="../assets/logo.png" />
    Schedules work
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
}
</script>
