<template>
  <v-container class="page-container p-3">
    <UserCompanyDetailContainer />
  </v-container>
</template>

<script>
import UserCompanyDetailContainer from "@/components/Container/UserCompanyDetailContainer.vue"
export default {
  components: { UserCompanyDetailContainer },
}
</script>
