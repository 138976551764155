<template>
  <v-container class="page-container p-3">
    <UserDepartmentDetailContainer />
  </v-container>
</template>

<script>
import UserDepartmentDetailContainer from "@/components/Container/UserDepartmentDetailContainer.vue"
export default {
  components: { UserDepartmentDetailContainer },
}
</script>
