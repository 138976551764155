var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-container main-page-table w-100 white users-table"},[_vm._m(0),_c('tbody',{staticClass:"white",class:_vm.loadingTable ? 'body-loading' : ''},[(_vm.loadingTable)?_c('div',{staticClass:"absolute-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.listDataComputed),function(data,idx){return _c('tr',{key:data.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(idx + 1))]),_c('td',[_c('p',{staticClass:"mb-0 limit-line-1 text-center"},[_vm._v(" "+_vm._s(data.name || "-")+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.department_computed ? data.department_computed.name : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.company_computed ? data.company_computed.name : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.user_count || 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.created_at ? _vm.formatDateDMY(data.created_at) : "-")+" ")]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"table-actions-group d-flex",class:"group-status-4"},[(false)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.update))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewDetail(data.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.info))])]),(!data.user_count)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.revokeData(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.remove))])]):_vm._e()],1)])])}),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_vm._v("Đang tải dữ liệu...")])]):_vm._e(),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1)]):_vm._e(),(!_vm.isFirstLoading && (!_vm.listData || !_vm.listData.length))?_c('tr',{staticClass:"no-data"},[_vm._m(1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"primary white--text"},[_c('td',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_vm._v("STT")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"150px"}},[_vm._v("Tên chức danh")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"138px"}},[_vm._v("Bộ phận")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Công ty")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"220px"}},[_vm._v("Số nhân viên đương nhiệm")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Ngày tạo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"82px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('img',{staticClass:"mt-5",attrs:{"src":require("@/assets/images/no-user-filter.svg"),"width":"300px","alt":""}}),_c('p',{staticClass:"neutral_color_sub3--text my-4",staticStyle:{"font-size":"20px"}},[_vm._v(" Hiện chưa có dữ liệu ")])])}]

export { render, staticRenderFns }