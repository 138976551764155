<template>
  <v-container class="page-container p-3">
    <UsersContainer />
  </v-container>
</template>

<script>
import UsersContainer from "@/components/Container/UsersContainer.vue"
export default {
  components: { UsersContainer },
}
</script>
