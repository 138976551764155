var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"no-box-shadow white h-100"},[_c('v-navigation-drawer',{staticClass:"bg-transparent w-100 pt-3",attrs:{"mini-variant":_vm.mini,"permanent":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"sidebar_tab_navigator pt-0",attrs:{"dense":""}},_vm._l((_vm.itemsComputed),function(item){return _c('v-list-item',{key:item.title,staticClass:"each-tab-in-navigator",class:[
          item.pathNameMatch.includes(_vm.currentPathActive)
            ? item.isGroup
              ? 'sidebar_ico_active_color--text'
              : 'primary sidebar_ico_active_color--text'
            : 'neutral--text sidebar_ico_active_color',
          item.isGroup ? 'has-child' : '' ],attrs:{"link":""}},[(!item.isGroup)?_c('router-link',{staticClass:"v-list-item px-0 nav-link-item",attrs:{"to":item.path}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.pathNameMatch.includes(_vm.currentPathActive)
                  ? 'sidebar_ico_active_color'
                  : 'secondary_text'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"navigator-title"},[_c('span',{class:item.pathNameMatch.includes(_vm.currentPathActive)
                    ? 'sidebar_ico_active_color--text'
                    : 'neutral--text'},[_vm._v(" "+_vm._s(_vm.sysLanguage.sidebar[item.title]))])])],1)],1):_vm._e(),(item.isGroup)?_c('div',{staticClass:"w-100"},[_c('v-expansion-panels',{staticClass:"v-list-item px-0 w-100",attrs:{"accordion":""},model:{value:(item.panel),callback:function ($$v) {_vm.$set(item, "panel", $$v)},expression:"item.panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{class:item.pathNameMatch.includes(_vm.currentPathActive)
                    ? 'primary group-expand sidebar_ico_active_color--text'
                    : 'neutral--text sidebar_ico_active_color',attrs:{"expand-icon":"mdi-menu-down"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.pathNameMatch.includes(_vm.currentPathActive)
                        ? 'sidebar_ico_active_color'
                        : 'secondary_text'}},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"navigator-title"},[_c('span',{class:item.pathNameMatch.includes(_vm.currentPathActive)
                          ? 'sidebar_ico_active_color--text'
                          : 'neutral--text'},[_vm._v(" "+_vm._s(_vm.sysLanguage.sidebar[item.title]))])])],1)],1),_c('v-expansion-panel-content',{staticClass:"app_bg"},_vm._l((item.listChild),function(c){return _c('v-list-item',{key:c.title,staticClass:"each-tab-in-navigator my-0 px-0 child-nav",class:item.pathNameMatch.includes(_vm.currentPathActive)
                      ? 'app_bg sidebar_ico_active_color--text'
                      : 'neutral--text app_bg',staticStyle:{"min-height":"32px"},attrs:{"link":""}},[(!c.isGroup)?_c('router-link',{staticClass:"v-list-item my-0 nav-link-item",attrs:{"to":c.path}},[(c.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":c.pathNameMatch.includes(_vm.currentPathActive)
                            ? 'sidebar_ico_active_color'
                            : 'secondary_text'}},[_vm._v(_vm._s(c.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"navigator-title pl-4"},[_c('span',{class:c.pathNameMatch.includes(_vm.currentPathActive)
                              ? 'primary--text font-weight-bold'
                              : 'neutral--text'},[_vm._v(" "+_vm._s(_vm.sysLanguage.sidebar[c.title]))])])],1)],1):_vm._e()],1)}),1)],1)],1)],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }