var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-container main-page-table w-100 white users-table"},[_vm._m(0),_c('tbody',{staticClass:"white",class:_vm.loadingTable ? 'body-loading' : ''},[(_vm.loadingTable)?_c('div',{staticClass:"absolute-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.listDataComputed),function(data){return _c('tr',{key:data.id},[_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.copy(data.app_id)}}},[_c('div',{staticClass:"d-flex gap-2 align-center justify-start px-2"},[_c('span',[_vm._v(" "+_vm._s(data.app_id || "---"))]),_c('v-icon',{staticClass:"text-primary-500 pointer icon-copy",attrs:{"size":"18"}},[_vm._v("mdi-content-copy")])],1)]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.copy(data.app_secret)}}},[_c('div',{staticClass:"d-flex gap-2 align-center justify-center"},[_c('span',[_vm._v(" "+_vm._s(data.app_secret || "---"))]),_c('v-icon',{staticClass:"text-primary-500 pointer icon-copy",attrs:{"size":"18"}},[_vm._v("mdi-content-copy")])],1)]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.name || "---")+" ")]),_c('td',{staticClass:"text-center"},[(!data.root_url)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s("---")+" ")]):_vm._e(),_c('a',{attrs:{"href":data.root_url,"target":"_blank","noopenner":""}},[_vm._v(" "+_vm._s(data.root_url)+" ")])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"table-actions-group d-flex justify-end",class:"group-status-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.update))])]),(data.status && data.status.id === 10)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.revokeData(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.disable))])]):_vm._e(),(data.status && data.status.id !== 10)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.revokeData(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-lock-open-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.enable))])]):_vm._e()],1)])])}),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_vm._v("Đang tải dữ liệu...")])]):_vm._e(),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1)]):_vm._e(),(!_vm.isFirstLoading && (!_vm.listData || !_vm.listData.length))?_c('tr',{staticClass:"no-data"},[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('EmptyBody')],1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"primary white--text"},[_c('td',{staticClass:"text-left px-2"},[_vm._v("ID App")]),_c('td',{staticClass:"text-center"},[_vm._v("App Secret")]),_c('td',{staticClass:"text-center"},[_vm._v("Tên App")]),_c('td',{staticClass:"text-center"},[_vm._v("Link App")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"80px"}})])}]

export { render, staticRenderFns }