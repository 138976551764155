<template>
  <v-row class="m-0 p-0 pt-1 user-feed-rowflex-column align-start">
    <div class="content-slot-header py-2 px-3 w-100 d-flex align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            class="mb-0 mr-1"
            color="neutral"
            @click="fallBack"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </template>
        <span>Quay lại</span>
      </v-tooltip>
      <p class="mb-0">
        Chi tiết Công ty
        <span
          v-if="detailData.status"
          class="px-3 py-1 font-size-14 rounded text-white"
          :class="
            detailData.status && detailData.status.id === 10
              ? 'bg-info-500'
              : 'bg-error-500'
          "
        >
          {{ detailData.status.name }}
        </span>
      </p>
      <v-btn
        color="neutral_color_sub4"
        depressed
        style="border-radius: 8px"
        :disabled="loadingBtn"
        @click="fallBack"
        class="ml-auto px-5 neutral_color_sub2--text low-letter-spacing"
      >
        Hủy
      </v-btn>
      <v-btn
        color="primary"
        depressed
        :loading="loadingBtn"
        style="border-radius: 8px"
        class="ml-2 low-letter-spacing"
        @click="onSubmit"
      >
        Lưu
      </v-btn>
    </div>
    <v-col cols="12" class="py-0">
      <div class="mx-auto w-100 content-wrap-relative">
        <v-btn
          class="mx-auto my-5 d-block box-shadow--mod white btn-load-absolute"
          fab
          light
          small
          loading
          v-if="isFirstLoading"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <NewsLoader v-if="showSkeletonPost" />
        <div v-if="!showSkeletonPost" class="detail-content">
          <v-row
            class="white mx-0 my-0 px-8 pt-2 pb-4 mt-5 rounded-lg box-shadow--mod-lighter"
          >
            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 text--h5 neutral--text font-weight-500">
                  Thông tin định danh
                </p>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Tên công ty <span class="semantic_error--text">*</span>
                </p>
                <div
                  class="info-input"
                  :class="errorObj.name ? 'error-border' : ''"
                >
                  <input
                    v-model="name"
                    type="text"
                    :placeholder="'Công ty'"
                    class="input-no-focus w-100 font-size-16"
                  />
                </div>
                <p
                  v-if="errorObj.name"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.name }}
                </p>
              </div></v-col
            >

            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Mã số thuế
                </p>
                <div class="info-input">
                  <input
                    v-model="enterprise_gdt_code"
                    type="text"
                    :placeholder="'Mã số thuế'"
                    class="input-no-focus w-100 font-size-16"
                  />
                </div></div
            ></v-col>
          </v-row>
          <v-row
            class="white mx-0 my-0 px-8 pt-2 pb-4 mt-5 rounded-lg box-shadow--mod-lighter"
          >
            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 text--h5 neutral--text font-weight-500">
                  Thông tin liên hệ
                </p>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Số điện thoại công ty
                  <span class="semantic_error--text">*</span>
                </p>
                <div
                  class="info-input"
                  :class="errorObj.phone ? 'error-border' : ''"
                >
                  <input
                    v-model="phone"
                    type="text"
                    :placeholder="'Số điện thoại'"
                    class="input-no-focus w-100 font-size-16"
                  />
                </div>
                <p
                  v-if="errorObj.phone"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.phone }}
                </p>
              </div></v-col
            >

            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Địa chỉ
                </p>
                <div class="">
                  <textarea
                    v-model="address"
                    type="text"
                    :placeholder="'Mã số thuế'"
                    class="w-100 font-size-16 pt-3"
                    rows="5"
                  ></textarea>
                </div></div
            ></v-col>
          </v-row>
          <v-row
            class="white mx-0 my-0 px-8 pt-2 pb-4 mt-5 rounded-lg box-shadow--mod-lighter"
          >
            <v-col cols="12">
              <div class="each-top-info w-100">
                <p class="mb-1 text--h5 neutral--text font-weight-500">
                  Giấy phép
                </p>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Giấy phép đăng ký kinh doanh
                  <span class="semantic_error--text">*</span>
                </p>
                <FileUploaderWithPreview
                  :init-data="business_license_files || []"
                  :title="''"
                  :suffixIconName="'bi:upload'"
                  :local-file-removed="fileRemoved"
                  @on-change="onChangeLicenseFiles"
                  @removeLocalFile="removeLocalFile"
                />
                <p
                  v-if="errorObj.business_license_files"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.business_license_files }}
                </p>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="each-top-info w-100">
                <p class="mb-1 info-label neutral--text font-weight-500">
                  Dấu khống khách hàng
                  <span class="semantic_error--text">*</span>
                </p>
                <FileUploaderWithPreview
                  :init-data="company_seal_files || []"
                  :title="''"
                  :suffixIconName="'bi:upload'"
                  :local-file-removed="fileRemoved"
                  @on-change="onChangeSealFiles"
                  @removeLocalFile="removeLocalFile"
                />
                <p
                  v-if="errorObj.company_seal_files"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.company_seal_files }}
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import NewsLoader from "@/components/Loader/NewsLoader"
import FileUploaderWithPreview from "@/components/FileHandle/FileUploaderWithPreview"
import { formatDateDMYH } from "@/helpers/dateFormater.js"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import api from "@/services"
export default {
  components: {
    NewsLoader,
    FileUploaderWithPreview,
  },
  mixins: [objHandlerMixins],
  data() {
    return {
      isFirstLoading: true,
      detailData: {},
      loadingSearch: false,
      loadingBtn: false,
      submitClicked: false,

      name: "",
      code: "",
      phone: "",
      enterprise_gdt_code: "",
      address: "",
      business_license_files: [],
      company_seal_files: [],
      fileRemoved: [],
    }
  },
  computed: {
    showSkeletonPost() {
      return (
        this.isFirstLoading && (!this.detailComputed || !this.detailComputed.id)
      )
    },
    detailComputed() {
      if (this.detailData && this.detailData.id) {
        return this.detailData
      }
      return {}
    },
    errorObjUnder() {
      const obj = {}
      if (!this.name || !String(this.name).trim()) {
        obj.name = "Không được để trống tên công ty"
      }
      if (!this.phone) {
        obj.phone = "Không được để trống số điện thoại"
      }
      if (!this.company_seal_files.length) {
        obj.company_seal_files = "Không được để trống trường này"
      }
      if (!this.business_license_files.length) {
        obj.business_license_files = "Không được để trống trường này"
      }
      return obj
    },
    errorObj() {
      return this.submitClicked ? this.errorObjUnder : {}
    },
  },
  watch: {
    $route() {
      this.getDetailData()
    },
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    resolveReportLocal(report) {
      const index = this.listReportsData.findIndex((o) => o.id === report.id)
      if (index !== -1) {
        this.listReportsData.splice(index, 1, report)
      }
    },
    formatDateDMYH(_d) {
      return formatDateDMYH(_d)
    },

    fallBack() {
      const prvRoute = ""
      this.$router.push(prvRoute || "/company-management")
      // this.$router.go(-1)
    },
    async getDetailData(isSearch = false) {
      const payload = {}
      const id = this.$route.params.id
      if (isSearch) {
        this.loadingSearch = true
      }
      const res = await api.companyManagement.getDetailData(id, payload)
      this.isFirstLoading = false

      this.loadingSearch = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getNewsFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.detailData = dataObj.company
        this.name = dataObj.company.name
        this.code = dataObj.company.code
        this.phone = dataObj.company.phone
        this.enterprise_gdt_code = dataObj.company.enterprise_gdt_code
        this.address = dataObj.company.address
        this.company_seal_files = dataObj.company_seal_files || []
        this.business_license_files = dataObj.business_license_files || []
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async onSubmit() {
      this.submitClicked = true
      if (Object.keys(this.errorObjUnder).length) {
        return
      }
      // const body = {
      //   name: this.name,
      //   code: this.code,
      //   phone: this.phone,
      //   enterprise_gdt_code: this.enterprise_gdt_code,
      //   address: this.address,
      //   company_seal_files_ids: [...this.company_seal_files.map((o) => o.id)],
      //   business_license_files_ids: [
      //     ...this.business_license_files.map((o) => o.id),
      //   ],
      // }
      const formData = new FormData()
      formData.append("name", this.name || "")
      formData.append("code", this.code || "")
      formData.append("phone", this.phone || "")
      formData.append("enterprise_gdt_code", this.enterprise_gdt_code || "")
      formData.append("address", this.address || "")
      this.company_seal_files.map((o) => {
        formData.append("company_seal_file[]", o.id || "")
      })
      this.business_license_files.map((o) => {
        formData.append("business_license_files_id[]", o.id || "")
      })
      this.loadingBtn = true
      const res = await api.companyManagement.update(
        this.$route.params.id,
        formData,
      )
      this.loadingBtn = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.actionFailDefault,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          return
        }
        this.$store.commit(
          "toast/getSuccess",
          this.sysLanguage.snackbar.actionSuccessDefault,
        )
        this.getDetailData()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    removeLocalFile(item) {
      if (item.id) {
        this.fileRemoved.push(item.id)
      }
    },
    onChangeLicenseFiles(arr) {
      this.business_license_files = [...arr]
    },
    onChangeSealFiles(arr) {
      this.company_seal_files = [...arr]
    },
  },
}
</script>

<style>
.container-empty-background {
  display: block;
  /* margin: auto; */
  max-width: 95%;
}
</style>
<style lang="scss" scoped>
.user-feed-row {
  align-items: flex-start;
  min-height: 80vh;
}
.detail-content {
  border-radius: 8px;
  .table-wrapper {
    position: relative;
    /* height: calc(100% - 100px); */
    max-height: calc(100vh - 400px);
    padding-bottom: 70px;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #e6e6e600;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: rgba(176, 176, 176, 0.526);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: rgb(96, 96, 96);
      }
    }
  }
}
.fallback-header {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
}
.each-top-info {
  padding: 0 20px;
  .info-label {
    font-size: 16px;
    line-height: 28px;
  }
  .info-input {
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 8px;
    height: 48px;
    padding: 10px 12px;
  }
  textarea {
    padding: 10px 12px;
    border: 1px solid;
    border-radius: 8px;
  }
}
.detail-table-title {
  border-bottom: 1px solid #f2f2f2;
}
.tab-control {
  border-bottom: 1px solid;
}
</style>
