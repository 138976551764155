<template>
  <v-container class="page-container p-3">
    <UserDetailContainer />
  </v-container>
</template>

<script>
import UserDetailContainer from "@/components/Container/UserDetailContainer.vue"
export default {
  components: { UserDetailContainer },
}
</script>
