<template>
  <v-container class="page-container p-3">
    <UserPositionDetailContainer />
  </v-container>
</template>

<script>
import UserPositionDetailContainer from "@/components/Container/UserPositionDetailContainer.vue"
export default {
  components: { UserPositionDetailContainer },
}
</script>
