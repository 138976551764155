const repo = (axios) => {
  return {
    getListData(payload) {
      return axios
        .get(`/api/admin/access-management/roles`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListPermissions(payload) {
      return axios
        .get(`/api/admin/access-management/permissions`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListApps(payload) {
      return axios
        .get(`/api/admin/applications`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDetailData(id, payload) {
      return axios
        .get(`/api/admin/access-management/roles/${id}`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListPermissionByAppId(id, payload) {
      return axios
        .get(`/api/admin/access-management/${id}/permissions`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    syncPermission(id, payload) {
      return axios
        .post(
          `/api/admin/access-management/roles/${id}/sync-permissions`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    addUsers(id, payload) {
      return axios
        .post(`/api/admin/access-management/roles/${id}/add-users`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    removeUser(id, payload) {
      return axios
        .post(`/api/admin/access-management/roles/${id}/remove-user`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    update(id, payload) {
      return axios
        .put(`/api/admin/access-management/roles/${id}`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    updateStatus(id, payload) {
      return axios
        .post(`/api/admin/access-management/roles/${id}/status`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    create(payload) {
      return axios
        .post(`/api/admin/access-management/roles`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    delete(_id) {
      return axios({
        method: "DELETE",
        url: `/api/admin/access-management/roles/${_id}`,
      })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}
export default repo
