<template>
  <v-container class="page-container p-3">
    <CompanyManagementContainer />
  </v-container>
</template>

<script>
import CompanyManagementContainer from "@/components/Container/CompanyManagementContainer.vue"
export default {
  components: { CompanyManagementContainer },
}
</script>
