import { render, staticRenderFns } from "./UserDepartmentDetailContainer.vue?vue&type=template&id=3f84715f&scoped=true&"
import script from "./UserDepartmentDetailContainer.vue?vue&type=script&lang=js&"
export * from "./UserDepartmentDetailContainer.vue?vue&type=script&lang=js&"
import style0 from "./UserDepartmentDetailContainer.vue?vue&type=style&index=0&lang=css&"
import style1 from "./UserDepartmentDetailContainer.vue?vue&type=style&index=1&id=3f84715f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f84715f",
  null
  
)

export default component.exports