var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-container main-page-table w-100 white users-table"},[_vm._m(0),_c('tbody',{staticClass:"white",class:_vm.loadingTable ? 'body-loading' : ''},[(_vm.loadingTable)?_c('div',{staticClass:"absolute-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.listDataComputed),function(data,idx){return _c('tr',{key:data.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(idx + 1))]),_c('td',[_c('p',{staticClass:"mb-0 limit-line-1 text-center semantic_info--text pointer hover-underline",on:{"click":function($event){return _vm.viewDetail(data.id)}}},[_vm._v(" "+_vm._s(data.code || "-")+" ")])]),_c('td',{staticClass:"text-center"},[_c('p',{staticClass:"mb-0 w-100 text-center"},[_vm._v(" "+_vm._s(data.name || "-")+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.user_count || 0)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.department_count || 0)+" ")]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"table-actions-group d-flex",class:"group-status-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.update))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewDetail(data.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.info))])]),(!data.default && !data.user_count)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.revokeData(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.remove))])]):_vm._e()],1)])])}),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_vm._v("Đang tải dữ liệu...")])]):_vm._e(),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1)]):_vm._e(),(!_vm.isFirstLoading && (!_vm.listData || !_vm.listData.length))?_c('tr',{staticClass:"no-data"},[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('EmptyBody',{attrs:{"isSearch":_vm.isSearch}})],1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"primary white--text"},[_c('td',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_vm._v("STT")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"150px"}},[_vm._v("Tên viết tắt")]),_c('td',{staticClass:"text-center"},[_vm._v("Công ty")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"110px"}},[_vm._v("Số nhân viên")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"130px"}},[_vm._v("Số chức danh")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"82px"}})])}]

export { render, staticRenderFns }