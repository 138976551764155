import { getCookie } from "@/helpers/customizeCookie.js"
const auth = () => {
  const cacheCToken = getCookie("token")
  const cacheCTokenO = getCookie("token")
  const cacheLToken = localStorage.getItem("token")
  if (
    (!cacheCToken || cacheCToken === "removed" || cacheCTokenO === "removed") &&
    !cacheLToken
  ) {
    return false
  }

  return true
}
export default auth
