<template>
  <div class="w-100">
    <div>
      <p class="mb-0 font-weight-bold">Danh sách người dùng được cấp quyền</p>
    </div>
    <div class="my-3 d-flex gap-5">
      <input
        v-model="search"
        title=""
        placeholder="Tìm kiếm người dùng"
        class="rounded-1 px-2 py-2 font-size-14 border-1 border-neutral-200 w-100 input-no-focus-outline"
        size="xxl"
      />

      <div
        class="border-1 border-primary-500 font-size-14 pointer justify-center ml-auto text-primary-500 px-2 py-0 d-flex align-center gap-1 rounded-1 bg-white transition"
        style="min-width: 160px"
        @click="addNewUser"
      >
        <v-icon class="text-primary-500" size="20px"> mdi-plus </v-icon> Thêm
        người dùng
      </div>
    </div>
    <div class="px-3 py-2 rounded-lg">
      <div
        class="d-flex pointer hover:bg-primary-50 align-center gap-4 rounded-lg hover:bg-basic-500/25 px-2 py-2"
        v-for="user in listUserComputed"
        :key="user.id"
      >
        <UserAvatarPreview
          :user="{ name: user.name || 'User', avatar: user.avatar || '' }"
          :size="40"
        />
        <div>
          <p class="text-neutral-900 body-md mb-0">{{ user.name }}</p>
          <p
            v-if="user.position && user.position.department"
            class="text-neutral-400 body-sm mb-0"
          >
            {{ user.position.name }} - {{ user.position.department.name }}
          </p>
          <p v-else class="text-neutral-400 body-sm mb-0">---</p>
        </div>

        <div
          class="ml-auto pointer d-flex align-center justify-center px-1 py-1 rounded bg-white"
          @click="onRemoveUser(user)"
        >
          <v-icon size="20px" class="text-red-500"
            >mdi-trash-can-outline</v-icon
          >
        </div>
      </div>
      <div
        v-if="!Array.isArray(listUserComputed) || !listUserComputed.length"
        class="py-10"
      >
        <p v-if="!search" class="text-center body-lg font-bold">
          Chưa có người dùng nào được thêm vào vai trò này
        </p>
        <p v-if="search" class="text-center body-lg font-bold">
          Không tìm thấy kết quả. Vui lòng thử lại
        </p>
      </div>
    </div>
    <DialogAddUser
      :isVisible="visibleUpdateUser"
      :confirmAction="onSubmitUpdateUser"
      :cancelAction="closeUpdateUserModal"
      :usersDefaultIds="listUserByIds"
    />
    <DialogConfirm
      :isVisible="visibleRemoveUser"
      :cancelAction="cancelRemoveUser"
      :confirmAction="removeUser"
      :cancelBtnText="sysLanguage.confirmDialog.cancelBtnTextNormal"
      :cancelBtnColor="'neutral_color_sub4'"
      :confirmBtnText="sysLanguage.confirmDialog.confirmBtnTextNormal"
      :title="confirmObj.title"
      :message="confirmObj.msg"
      :rawHtml="true"
      :loadingConfirmBtn="loadingRemove"
    />
  </div>
</template>
<script>
import UserAvatarPreview from "../Layout/UserAvatarPreview.vue"
import DialogAddUser from "@/components/Dialog/DialogAddUser.vue"
import DialogConfirm from "@/components/Dialog/DialogConfirm.vue"

import { isContainText } from "@/helpers/FormatnParse.js"
import api from "@/services"
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { UserAvatarPreview, DialogAddUser, DialogConfirm },
  props: {
    selectedRole: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      visibleRemoveUser: false,
      visibleUpdateUser: false,
      loadingRemove: false,
      loadingUpdate: false,
      loadingAddUser: false,
      search: "",
      selectedUserToRemove: null,
    }
  },
  computed: {
    confirmObj() {
      return this.selectedUserToRemove
        ? {
            title: "Xác nhận",
            msg: `Xóa người dùng <b>${this.selectedUserToRemove.name}</b> khỏi danh sách?`,
          }
        : {
            title: "Xác nhận",
            msg: "Xác nhận tác vụ",
          }
    },
    listUserByIds() {
      return this.selectedRole ? this.selectedRole.users.map((o) => o.id) : []
    },
    listUserByRole() {
      return this.selectedRole ? this.selectedRole.users : []
    },
    listUserComputed() {
      const ar = this.listUserByRole.filter((o) => {
        const isMatchByName = isContainText(o.name, this.search)
        const isMatchByDesc = isContainText(o.description || "", this.search)
        return isMatchByName || isMatchByDesc
      })
      return ar
    },
  },
  mounted() {
    this.syncData()
  },
  watch: {
    detailRole: {
      deep: true,
      handler() {
        this.syncData()
      },
    },
  },
  methods: {
    syncData() {},
    onRemoveUser(user) {
      this.selectedUserToRemove = user
      this.visibleRemoveUser = true
    },
    removeUser() {
      if (!this.selectedRole) {
        return
      }
      const body = {
        user_id: this.selectedUserToRemove.id || null,
      }

      this.loadingRemove = true
      api.roleManagement
        .removeUser(this.selectedRole.id, body)
        .then(
          () => {
            this.$store.commit("toast/getSuccess", "Xoá người dùng thành công")
            this.cancelRemoveUser()
            this.$emit("user-change")
          },
          () => {
            this.$store.commit("toast/getError", "Xoá người dùng thất bại")
          },
        )
        .catch((err) => {
          console.log(err)
          this.$store.commit("toast/getError", "Xoá người dùng thất bại")
        })
        .finally(() => {
          this.loadingRemove = false
        })
    },
    cancelRemoveUser() {
      this.visibleRemoveUser = false
      this.selectedUserToRemove = null
    },
    addNewUser() {
      this.visibleUpdateUser = true
    },
    onSubmitUpdateUser(payload) {
      if (!this.selectedRole) {
        return
      }
      const body = {
        user_ids: payload,
      }

      this.loadingAddUser = true
      api.roleManagement
        .addUsers(this.selectedRole.id, body)
        .then(
          () => {
            this.$store.commit("toast/getSuccess", "Thêm người dùng thành công")
            this.closeUpdateUserModal()
            this.$emit("user-change")
          },
          () => {
            this.$store.commit(
              "toast/getError",
              this.sysLanguage.snackbar.getUsersFail,
            )
          },
        )
        .catch(() => {
          this.$store.commit(
            "toast/getError",
            this.sysLanguage.snackbar.getUsersFail,
          )
        })
        .finally(() => {
          this.loadingAddUser = false
        })
    },
    closeUpdateUserModal() {
      this.visibleUpdateUser = false
    },
  },
}
</script>
