const repo = (axios) => {
  return {
    getListData(payload) {
      return axios
        .get(`/api/admin/file-management/files`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    upload(payload) {
      return axios
        .post(`/api/admin/file-management/files`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    delete(_id) {
      return axios({
        method: "DELETE",
        url: `/api/admin/file-management/files/${_id}`,
      })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}
export default repo
