<template>
  <v-container class="page-container p-3">
    <CustomerCompanyDetailContainer />
  </v-container>
</template>

<script>
import CustomerCompanyDetailContainer from "@/components/Container/CustomerCompanyDetailContainer.vue"
export default {
  components: { CustomerCompanyDetailContainer },
}
</script>
