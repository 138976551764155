<template>
  <v-container class="page-container p-3">
    <NewsFeedContainer />
  </v-container>
</template>

<script>
import NewsFeedContainer from "@/components/Container/NewsFeedContainer.vue"
export default {
  components: { NewsFeedContainer },
}
</script>
