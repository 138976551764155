<template>
  <v-container class="page-container p-3">
    <UserApplicationContainer />
  </v-container>
</template>

<script>
import UserApplicationContainer from "@/components/Container/UserApplicationContainer.vue"
export default {
  components: { UserApplicationContainer },
}
</script>
