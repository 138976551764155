<template>
  <v-container class="page-container p-3">
    <UserGroupContainer />
  </v-container>
</template>

<script>
import UserGroupContainer from "@/components/Container/UserGroupContainer.vue"
export default {
  components: { UserGroupContainer },
}
</script>
