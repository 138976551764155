<template>
  <v-container class="page-container p-3">
    <RoleManagementContainer />
  </v-container>
</template>

<script>
import RoleManagementContainer from "@/components/Container/RoleManagementContainer.vue"
export default {
  components: { RoleManagementContainer },
}
</script>
