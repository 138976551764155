<template>
  <v-container class="page-container p-3">
    <NewsDetailContainer />
  </v-container>
</template>

<script>
import NewsDetailContainer from "@/components/Container/NewsDetailContainer.vue"
export default {
  components: { NewsDetailContainer },
}
</script>
