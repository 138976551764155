import { render, staticRenderFns } from "./DialogUpdateCustomerCompany.vue?vue&type=template&id=31ed451c&scoped=true&"
import script from "./DialogUpdateCustomerCompany.vue?vue&type=script&lang=js&"
export * from "./DialogUpdateCustomerCompany.vue?vue&type=script&lang=js&"
import style0 from "./DialogUpdateCustomerCompany.vue?vue&type=style&index=0&id=31ed451c&lang=scss&scoped=true&"
import style1 from "./DialogUpdateCustomerCompany.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ed451c",
  null
  
)

export default component.exports