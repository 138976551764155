<template>
  <div class="group-block px-3 py-4 rounded-1 mb-4">
    <h3 class="pb-3 mb-3 page-border-btm">
      {{ group.group }}
    </h3>
    <div class="row">
      <div class="col-12">
        <label
          :for="`permission-${removeAscentNormal(group.group).replaceAll(
            ' ',
            '-',
          )}-selectAll`"
          class="pointer body-md hover:bg-primary-50 font-size-14 px-2 py-1 flex items-center gap-2 rounded"
          @click="toggleSelectAll"
        >
          <v-icon size="20px" color="primary" v-show="isSelectAll">
            mdi-checkbox-marked
          </v-icon>
          <v-icon size="20px" v-show="!isSelectAll">
            mdi-checkbox-blank-outline
          </v-icon>
          Tất cả
        </label>
      </div>
      <div
        class="col-4 pointer py-0"
        v-for="permission in group.items"
        :key="permission.id"
      >
        <label
          :for="`permission-${permission.id}`"
          class="pointer body-md hover:bg-primary-50 font-size-14 px-2 py-1 d-flex align-center gap-2 rounded"
        >
          <v-icon
            size="20px"
            color="primary"
            v-show="selectedIds.includes(permission.id)"
          >
            mdi-checkbox-marked
          </v-icon>
          <v-icon size="20px" v-show="!selectedIds.includes(permission.id)">
            mdi-checkbox-blank-outline
          </v-icon>

          <input
            v-model="selectedIds"
            :value="permission.id"
            type="checkbox"
            :id="`permission-${permission.id}`"
            class="accent-basic-500 text-white d-none"
          />
          {{ permission.description }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { removeAscentNormal } from "@/helpers/FormatnParse.js"
export default {
  props: {
    group: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedRole: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return { collapse: false, selectedIds: [], isSelectAll: false }
  },
  computed: {
    listItemComputed() {
      return Array.isArray(this.group.items) ? this.group.items : []
    },
  },
  mounted() {
    this.syncData()
  },
  watch: {
    selectedIds: {
      deep: true,
      handler() {
        this.$emit("permission-change", {
          group: this.group,
          selectedIds: this.selectedIds,
        })
        if (this.selectedIds.length && this.listItemComputed.length) {
          this.isSelectAll = this.listItemComputed.every((o) =>
            this.selectedIds.includes(o.id),
          )
        } else {
          this.isSelectAll = false
        }
      },
    },
    selectedRole: {
      deep: true,
      handler() {
        this.syncData()
      },
    },
  },
  methods: {
    removeAscentNormal,
    syncData() {
      if (this.selectedRole && this.selectedRole.permissions) {
        this.selectedIds = this.selectedRole.permissions
          .filter((o) => o.group === this.group.group)
          .map((o) => o.id)
      } else {
        this.selectedIds = []
      }
    },
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.selectedIds = []
      } else {
        this.selectedIds = this.group.items.map((o) => o.id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-border-btm {
  border-bottom: 1px solid #e5e5e5 !important;
}
.group-block {
  border: 1px solid #e5e5e5 !important;
}
</style>
