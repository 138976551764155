var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-container main-page-table w-100 white news-table"},[_vm._m(0),_c('tbody',{staticClass:"white",class:_vm.loadingTable ? 'body-loading' : ''},[(_vm.loadingTable)?_c('div',{staticClass:"absolute-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.listData),function(data,idx){return _c('tr',{key:data.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.tableStartIndex + idx + 1))]),_c('td',[_c('p',{staticClass:"mb-0 limit-line-2"},[_vm._v(" "+_vm._s(data.title)+" ")])]),_c('td',{staticClass:"text-center",class:_vm.parseNewsStatusTextClass(data.status_id)},[_vm._v(" "+_vm._s(data.status_id ? _vm.parseNewsStatus(data.status_id) : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.creator ? data.creator.name : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.created_at ? _vm.formatDateDMY(data.created_at) : "-")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(data.total_like || "0"))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(data.total_read || "0"))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(data.total_comment || "0"))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(data.total_report || "0"))]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"table-actions-group d-flex",class:("group-status-" + (data.status_id))},[(data.status_id === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.update))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.viewDetail(data.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.info))])]),(data.status_id !== 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.revokeData(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.sysLanguage.tooltip.revoke))])]):_vm._e()],1)])])}),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_vm._v("Đang tải dữ liệu...")])]):_vm._e(),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1)]):_vm._e(),(!_vm.isFirstLoading && (!_vm.listData || !_vm.listData.length))?_c('tr',{staticClass:"no-data"},[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('EmptyBody',{attrs:{"isSearch":_vm.isSearch}})],1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"primary white--text"},[_c('td',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_vm._v("ID")]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"150px"}},[_vm._v("Tiêu đề")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"100px"}},[_vm._v("Trạng thái")]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"150px"}},[_vm._v("Người tạo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"138px"}},[_vm._v("Ngày tạo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Lượt thích")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Lượt xem")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Lượt bình luận")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Báo cáo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}})])}]

export { render, staticRenderFns }