<template>
  <v-container class="page-container p-3">
    <UserDepartmentContainer />
  </v-container>
</template>

<script>
import UserDepartmentContainer from "@/components/Container/UserDepartmentContainer.vue"
export default {
  components: { UserDepartmentContainer },
}
</script>
