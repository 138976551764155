<template>
  <table class="table-container main-page-table w-100 white users-table">
    <thead class="primary white--text">
      <td class="text-center" style="width: 100px">Mã công ty</td>
      <td class="text-center" style="width: 100px">Trạng thái</td>
      <td class="text-center" style="width: 150px">Tên công ty</td>
      <td class="text-center" style="width: 100px">Mã số thuế</td>
      <td class="text-center" style="width: 100px">Số điện thoại</td>
      <td class="text-center" style="width: 150px">Địa chỉ</td>
      <td class="text-center" style="width: 80px"></td>
    </thead>
    <tbody class="white" :class="loadingTable ? 'body-loading' : ''">
      <div class="absolute-loading" v-if="loadingTable">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <tr v-for="data in listDataComputed" :key="data.id">
        <td>
          <p
            class="mb-0 limit-line-1 text-center semantic_info--text pointer hover-underline"
            @click="viewDetail(data.id)"
          >
            #{{ data.code }}
          </p>
        </td>
        <td class="text-center">
          <p class="mb-0 w-100 text-center">
            <span
              class="px-3 py-1 rounded text-white"
              :class="
                data.status && data.status.id === 10
                  ? 'bg-info-500'
                  : 'bg-error-500'
              "
            >
              {{ data.status.name }}
            </span>
          </p>
        </td>

        <td class="text-center">
          {{ data.name || "---" }}
        </td>
        <td class="text-center">
          {{ data.enterprise_gdt_code || "---" }}
        </td>
        <td class="text-center">
          {{ data.phone || "---" }}
        </td>
        <td class="text-center">
          {{ data.address || "---" }}
        </td>
        <td class="text-center">
          <div
            class="table-actions-group d-flex justify-end"
            :class="`group-status-4`"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="selectDataToUpdate(data)"
                >
                  <v-icon color="neutral_color_sub1">
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ sysLanguage.tooltip.update }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="viewDetail(data.id)"
                >
                  <v-icon color="neutral_color_sub1">
                    mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ sysLanguage.tooltip.info }}</span>
            </v-tooltip>
            <v-tooltip v-if="data.status && data.status.id === 10" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="revokeData(data)">
                  <v-icon color="neutral_color_sub1"> mdi-cancel </v-icon>
                </v-btn>
              </template>
              <span>{{ sysLanguage.tooltip.disable }}</span>
            </v-tooltip>
            <v-tooltip v-if="data.status && data.status.id !== 10" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="revokeData(data)">
                  <v-icon color="neutral_color_sub1">
                    mdi-lock-open-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ sysLanguage.tooltip.enable }}</span>
            </v-tooltip>
          </div>
        </td>
      </tr>

      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">Đang tải dữ liệu...</td>
      </tr>
      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">
          <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </td>
      </tr>
      <tr
        v-if="!isFirstLoading && (!listData || !listData.length)"
        class="no-data"
      >
        <td colspan="100" class="text-center py-5">
          <EmptyBody :isSearch="isSearch" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDateDMY } from "@/helpers/dateFormater.js"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import {
  parseUsersStatus,
  parseUsersStatusTextClass,
} from "@/helpers/FormatnParse.js"
import { mapState } from "vuex"
export default {
  mixins: [objHandlerMixins],
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
    revokeData: {
      type: Function,
      default() {},
    },
    viewDetail: {
      type: Function,
      default() {},
    },
    selectDataToUpdate: {
      type: Function,
      default() {},
    },
    isFirstLoading: {
      type: Boolean,
      default: true,
    },
    loadingTable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.users.companies,
      listUsers: (state) => state.users.listUsers,
    }),
    listDataComputed() {
      return this.listData.map((o) => ({
        ...o,
        department_computed: o.department ? o.department : null,
        company_computed:
          o.department && o.department.company_id
            ? this._arrayFilter(this.companies, "id", o.department.company_id)
            : null,
        creator_computed: o.creator_id
          ? this._arrayFilter(this.listUsers, "id", o.creator_id)
          : null,
      }))
    },
  },
  methods: {
    formatDateDMY(_d) {
      return formatDateDMY(_d)
    },
    parseUsersStatus(_d) {
      return parseUsersStatus(_d)
    },
    parseUsersStatusTextClass(_d) {
      return parseUsersStatusTextClass(_d)
    },
  },
}
</script>
<style lang="scss" scoped>
.users-table {
  tbody {
    td {
      font-size: 14px !important;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}

.group-status-2 {
}
.group-status-3 {
  padding-right: 36px;
}
.table-actions-group {
  justify-content: flex-start;
}
.v-application {
  .body-loading {
    opacity: 0.8;
    /* background-color: rgba(239, 240, 240, 0.563) !important; */
    pointer-events: none;
    position: relative;
  }
  .absolute-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 200px;
    background-color: rgba(239, 240, 240, 0.563) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style>
.limit-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px;
  display: block;

  -webkit-box-orient: vertical;
}
</style>
