<template>
  <v-container class="page-container p-3">
    <UserPositionContainer />
  </v-container>
</template>

<script>
import UserPositionContainer from "@/components/Container/UserPositionContainer.vue"
export default {
  components: { UserPositionContainer },
}
</script>
