<template>
  <div
    class="news-container m-0 px-3 py-4 content-slot-container white box-shadow--mod-lighter"
  >
    <div class="d-flex h-100 gap-3">
      <div class="list-roles rounded-2">
        <div
          class="d-flex w-100 align-items-center gap-3 justify-space-between px-3 roles-title py-3"
        >
          <h3>Danh sách vai trò</h3>
          <v-btn
            color="primary"
            depressed
            style="border-radius: 4px"
            class="low-letter-spacing v-btn-normalize py-2"
            @click="visibleDialogCreate = true"
          >
            Tạo vai trò mới
          </v-btn>
        </div>

        <div class="px-3 py-3">
          <div class="each-filter">
            <div class="filter-input">
              <v-icon color="#bbbbbb" size="20">mdi-magnify</v-icon>
              <input
                v-model="search"
                type="text"
                :placeholder="sysLanguage.placeholder.textSearch"
                class="input-no-focus font-size-14"
              />
            </div>
          </div>

          <div class="list-data">
            <div
              v-for="item in listDataComputed"
              :key="item.id"
              class="py-2 px-2 pointer d-flex align-center gap-3 mt-2 role-block rounded-1"
              :class="selectedRoleId === item.id ? 'role-active' : ''"
              @click="selectedRoleId = item.id"
            >
              <img
                src="@/assets/images/group-avatar.svg"
                alt="role avatar"
                class="rounded-full object-cover"
                style="height: 40px"
              />
              <span class="font-semibold body-md w-full role-name">
                {{ item.name }}</span
              >
              <v-icon
                :color="selectedRoleId === item.id ? '#fff' : '#000'"
                size="16"
                class="ml-auto"
                >mdi-chevron-right</v-icon
              >
            </div>

            <p
              v-if="!listDataComputed || (!listDataComputed.length && search)"
              class="text-center font-weight-bold mt-5"
            >
              Không tìm thấy dữ liệu, vui lòng thử lại.
            </p>
          </div>
        </div>
      </div>

      <div
        class="detail-role w-100 h-100 px-3 d-flex rounded-2 pb-3"
        :class="loadingDetailRole ? 'is-loading' : ''"
      >
        <h2
          v-if="!isFirstLoading && !selectedRoleId"
          class="text-center w-100"
          style="align-self: center"
        >
          Vui lòng chọn 1 vai trò để xem chi tiết
        </h2>
        <div v-if="selectedRole" class="w-100">
          <div
            class="detail-role-name-block d-flex align-center gap-3 page-border-btm py-2"
          >
            <p v-if="!isEditRoleName" class="detail-role-name mb-0">
              {{ selectedRole.name }}
            </p>
            <input
              v-if="isEditRoleName"
              v-model="roleName"
              autofocus
              type="text"
              :placeholder="'Tên vai trò'"
              class="input-no-focus detail-role-name input-border-bottom"
            />
            <div
              v-if="!isEditRoleName"
              class="trigger-edit-btn font-size-14 text-primary-500 hover:bg-primary-50 pointer d-flex align-center gap-2 items-center rounded-1 px-2 py-1 border-1 border-primary-500"
              @click="onEditRoleName"
            >
              <v-icon color="primary" size="18">mdi-pencil</v-icon>
              Chỉnh sửa
            </div>
            <div v-if="isEditRoleName" class="d-flex align-center gap-2">
              <div
                class="trigger-edit-btn font-size-14 text-primary-500 hover:bg-primary-50 pointer d-flex align-center gap-2 items-center rounded-1 px-2 py-1 border-1 border-primary-500"
                @click="update"
              >
                Lưu
              </div>
              <div
                class="trigger-edit-btn font-size-14 text-error-500 hover:bg-error-100 pointer d-flex align-center gap-2 items-center rounded-1 px-2 py-1 border-1 border-error-500"
                @click="cancelEditRoleName"
              >
                Hủy
              </div>
            </div>
            <div
              v-if="currentTab === 1"
              class="d-flex gap-2 align-center pointer text-primary-500 hover:bg-primary-50 py-1 px-1 w-fit ml-auto rounded-2"
              @click="currentTab = 2"
            >
              NGƯỜI DÙNG ĐƯỢC CẤP QUYỀN
              <v-icon color="#primary-500" size="20"
                >mdi-chevron-double-right</v-icon
              >
            </div>
            <div
              v-if="currentTab === 2"
              class="d-flex gap-2 align-center pointer text-primary-500 hover:bg-primary-50 py-1 px-1 w-fit ml-auto rounded-2"
              @click="currentTab = 1"
            >
              <v-icon color="#primary-500" size="20"
                >mdi-chevron-double-left</v-icon
              >
              DANH SÁCH QUYỀN
            </div>
          </div>

          <div
            v-if="listApp && listApp.length && currentTab === 1"
            class="d-flex align-center justify-center w-fit my-5 mx-auto py-2 px-2 bg-primary-500 rounded-1"
          >
            <div
              v-for="(app, index) in listApp"
              :key="index"
              class="each-app px-3 py-2 font-size-12 rounded-1 text-center pointer text-uppercase"
              :class="
                selectedAppId === app.id
                  ? 'bg-white text-black font-weight-bold'
                  : 'bg-transparent text-white'
              "
              @click="selectedAppId = app.id"
            >
              {{ app.name }}
            </div>
          </div>

          <div class="pb-3 group-pms scroll-y-mod_big d-flex gap-3 mt-5">
            <div v-if="currentTab === 1" class="w-100">
              <GroupPermission
                v-for="item in permissionsByGroup"
                :key="item.group"
                :selected-role="selectedRole"
                :group="item"
                @permission-change="permissionChange"
              />
            </div>
            <div v-if="currentTab === 2" class="w-100">
              <GroupUsers
                :selected-role="selectedRole"
                @user-change="getDetailRole(true)"
              />
            </div>
            <div
              v-if="selectedRole"
              class="rounded-1 border-1 border-neutral-200 px-2 py-3 detail-role-desc"
              style="width: 300px; min-width: 300px; height: fit-content"
            >
              <div
                class="d-flex pb-2 align-center justify-between gap-2 page-border-bottom"
              >
                <p class="mb-0 font-weight-500">Mô tả</p>
                <div
                  v-if="!isEditDesc"
                  class="pointer px-1 py-1 rounded hover:bg-primary-50 w-fit text-primary-500"
                  @click="onEditDescription"
                >
                  <v-icon color="primary" size="18">mdi-pencil</v-icon>
                </div>
              </div>
              <div class="d-flex flex-column gap-5">
                <div>
                  <p
                    v-if="!isEditDesc"
                    class="text-neutral-500 body-md py-1 mb-0"
                  >
                    {{ selectedRole.description || "(Không có mô tả)" }}
                  </p>
                  <textarea
                    v-if="selectedRole && isEditDesc"
                    autofocus
                    v-model="description"
                    class="w-100 rounded-2 mt-2 border-1 border-neutral-400 px-1 py-2 ring-0 outline-0"
                    placeholder="Nhập mô tả"
                    rows="3"
                  ></textarea>
                  <div
                    v-if="selectedRole && isEditDesc"
                    class="d-flex align-center gap-2 w-100"
                  >
                    <div
                      class="pointer rounded-1 w-50 text-red-500 w-full py-1 border-1 border-red-500 text-center hover:bg-red-100"
                      @click="cancelEditDesc"
                    >
                      Hủy
                    </div>
                    <div
                      class="pointer rounded-1 w-50 text-white w-full py-1 bg-primary-500 border-1 border-primary-500 cursor-pointer text-center rounded-md"
                      @click="update"
                    >
                      Lưu
                    </div>
                  </div>
                </div>

                <div class="d-flex align-center gap-2">
                  <div
                    class="pointer font-size-14 font-weight-bold border border-neutral-200 bg-neutral-100 rounded px-2 py-1 flex items-center gap-2 text-neutral-900 w-fit"
                    @click="currentTab = 2"
                  >
                    <v-icon size="24px">mdi-account-multiple-check</v-icon>
                    {{ selectedRole.users.length }} người
                  </div>
                  <div
                    class="pointer font-size-14 font-weight-bold border border-neutral-200 bg-neutral-100 rounded px-2 py-1 flex items-center gap-2 text-neutral-900 w-fit"
                    @click="currentTab = 1"
                  >
                    <v-icon size="24px">mdi-star</v-icon>
                    {{ selectedRole.permissions.length }} quyền
                  </div>
                </div>

                <div class="text-neutral-500 font-size-14 text-left">
                  Chỉnh sửa lần cuối lúc:
                  <span class="font-bold block">
                    {{ formatDateHM(selectedRole.updated_at) }} ngày
                    {{ formatDateDMY(selectedRole.updated_at) }}
                  </span>
                  bởi
                  <span class="text-primary-500">
                    {{
                      selectedRole.updater ? selectedRole.updater.name : "---"
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="selectedRole" class="update-block">
          <v-btn
            color="primary"
            depressed
            style="border-radius: 4px"
            :loading="loadingUpdate"
            class="low-letter-spacing v-btn-normalize py-2"
            @click="syncPermission"
          >
            Cập nhật
          </v-btn>
        </div>
      </div>
    </div>
    <DialogCreateRole
      :isVisible="visibleDialogCreate"
      :cancelAction="cancelCreateData"
      @success="addedNewRole"
    />
  </div>
</template>

<script>
import api from "@/services"
import DialogCreateRole from "@/components/Dialog/DialogCreateRole"
import GroupPermission from "@/components/Role/GroupPermission"
import GroupUsers from "@/components/Role/GroupUsers"
import { isContainText } from "@/helpers/FormatnParse.js"
import { mapState } from "vuex"
import { formatDateDMY, formatDateHM } from "@/helpers/dateFormater.js"
export default {
  components: { GroupPermission, GroupUsers, DialogCreateRole },

  data() {
    return {
      listRoles: [],
      listApp: [],
      listAppPermission: [],
      selectedRolePermission: [],
      loadingSearch: false,
      loadingUpdate: false,
      loadingPermissionByAppId: false,
      loadingDetailRole: false,
      isFirstLoading: true,
      visibleDialogCreate: false,
      forcedLoading: false,
      refIntersect: 0,
      selectRef: 0,
      search: "",
      firstSync: true,
      selectedAppId: null,
      selectedRoleId: null,
      selectedRole: null,
      roleName: "",
      isEditRoleName: false,
      description: "",
      isEditDesc: false,
      currentTab: 1,
    }
  },
  computed: {
    ...mapState({}),

    showSkeletonPost() {
      return !this.isFirstLoading
    },
    // selectedRole() {
    //   return this.listRoles.find((o) => o.id === this.selectedRoleId)
    // },
    listDataComputed() {
      const ar = this.listRoles.filter((o) => {
        const isMatchByName = isContainText(o.name, this.search)
        const isMatchByDesc = isContainText(o.description || "", this.search)
        return isMatchByName || isMatchByDesc
      })
      return ar
    },
    permissionsByGroup() {
      const listPermission = this.listAppPermission.map((o) => ({
        ...o,
        group: o.group || "Không rõ",
      }))
      const listGroup = [...new Set(listPermission.map((o) => o.group))]
      return listGroup.map((o) => {
        return {
          group: o,
          items: listPermission.filter((k) => k.group === o),
        }
      })
    },
  },
  created() {
    if (this.listNews && this.listNews.length) {
      this.isFirstLoading = false
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.getListData()
      },
    },
    selectedRoleId: {
      deep: true,
      handler() {
        this.getDetailRole()
        if (this.selectedRoleId && !this.selectedAppId) {
          this.selectedAppId =
            Array.isArray(this.listApp) && this.listApp.length
              ? this.listApp[0].id
              : null
        }
      },
    },
    selectedRole: {
      deep: true,
      handler() {
        this.selectedRolePermission = []
        this.syncSelectedRole()
        this.syncPermissionToLocal()
      },
    },
    selectedAppId() {
      this.getListPermissionByAppId()
    },
  },
  mounted() {
    this.getListRoles()
    // this.getListPermission()
    this.getListApps()
  },
  methods: {
    formatDateDMY,
    formatDateHM,
    syncSelectedRole() {
      if (this.selectedRole) {
        this.roleName = this.selectedRole.name
      } else {
        this.roleName = ""
      }
    },
    async getListRoles() {
      this.loadingSearch = true
      const query = { ...this.$route.query }
      const payload = { ...query, pagination: false }
      const res = await api.roleManagement.getListData(payload)
      this.isFirstLoading = false
      this.loadingSearch = false
      this.firstSync = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.listRoles = dataObj.roles
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async getListApps() {
      const query = { ...this.$route.query }
      const payload = { ...query, pagination: false }
      const res = await api.roleManagement.getListApps(payload)
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.listApp = dataObj.applications
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async getListPermission() {
      const query = { ...this.$route.query }
      const payload = { ...query, pagination: false }
      const res = await api.roleManagement.getListPermissions(payload)
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        // const dataObj = res.data.data
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async getDetailRole() {
      if (!this.selectedRoleId) {
        return
      }
      this.loadingDetailRole = true
      const payload = { pagination: false }
      const res = await api.roleManagement.getDetailData(
        this.selectedRoleId,
        payload,
      )
      this.loadingDetailRole = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.selectedRole = dataObj.role
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async getListPermissionByAppId() {
      if (!this.selectedAppId) {
        return
      }
      this.loadingPermissionByAppId = true
      const payload = { pagination: false }
      const res = await api.roleManagement.getListPermissionByAppId(
        this.selectedAppId,
        payload,
      )
      this.loadingPermissionByAppId = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.getCompaniesFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.listAppPermission = dataObj.permissions
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    cancelEditRoleName() {
      this.isEditRoleName = false
      this.roleName = ""
    },
    onEditRoleName() {
      if (this.selectedRole) {
        this.roleName = this.selectedRole.name
      } else {
        this.roleName = ""
      }
      this.isEditRoleName = true
    },
    onEditDescription() {
      this.isEditDesc = true
      this.description = this.selectedRole?.description || ""
    },
    cancelEditDesc() {
      this.isEditDesc = false
      this.description = ""
    },
    async update() {
      if (!this.selectedRoleId || !this.selectedRole) {
        return
      }
      const body = {
        name: this.roleName || this.selectedRole?.name,
        description: this.description || this.selectedRole?.description,
      }

      this.loadingUpdate = true
      api.roleManagement
        .update(this.selectedRoleId, body)
        .then(
          (res) => {
            this.$store.commit(
              "toast/getSuccess",
              "Cập nhật vai trò thành công ",
            )
            this.selectedRole = res.data.role
            this.cancelEditDesc()
            this.cancelEditRoleName()
            this.getListRoles()
            this.getDetailRole()
          },
          (err) => {
            this.$store.commit(
              "toast/getError",
              err?.data?.message || "Cập nhật vai trò thất bại",
            )
          },
        )
        .catch((err) => {
          this.$store.commit(
            "toast/getError",
            err?.data?.message || "Cập nhật vai trò thất bại",
          )

          this.selectedRole = null
        })
        .finally(() => {
          this.loadingUpdate = false
        })
    },
    syncPermissionToLocal() {
      if (this.selectedRole) {
        const listPermission = this.selectedRole.permissions.map((o) => ({
          ...o,
          group: o.group || "Không rõ",
        }))
        const listGroup = [...new Set(listPermission.map((o) => o.group))]
        this.selectedRolePermission = listGroup.map((o) => {
          return {
            group: o,
            items: listPermission.filter((k) => k.group === o).map((k) => k.id),
          }
        })
      } else {
        this.selectedRolePermission = []
      }
    },
    permissionChange(payload) {
      // this.selectedRolePermission =

      const matchIdx = this.selectedRolePermission.findIndex(
        (o) => o.group === payload.group.group,
      )

      if (matchIdx !== -1) {
        const obj = { ...this.selectedRolePermission[matchIdx] }
        obj.items = payload.selectedIds
        this.selectedRolePermission.splice(matchIdx, 1, obj)
      } else {
        this.selectedRolePermission.push({
          group: payload.group.group,
          items: payload.selectedIds,
        })
      }
    },
    async syncPermission() {
      if (!this.selectedRoleId) {
        return
      }
      const body = {
        permission_ids: this.selectedRolePermission.map((o) => o.items).flat(),
      }

      this.loadingUpdate = true
      await api.roleManagement
        .syncPermission(this.selectedRoleId, body)
        .then(
          (res) => {
            // this.$globalHelpers.setSnack({
            //   text: "Cập nhật vai trò thành công ",
            //   type: "success",
            // })
            this.$store.commit(
              "toast/getSuccess",
              "Cập nhật vai trò thành công",
            )
            this.selectedRole = res.data.data.role
          },
          (err) => {
            console.log(err)
            this.$store.commit("toast/getError", "Cập nhật vai trò thất bại")
            // this.$globalHelpers.setSnack({
            //   text: err?.data?.message || "Cập nhật vai trò thất bại",
            //   type: "error",
            // })
          },
        )
        .catch((err) => {
          console.log(err)
          this.$store.commit("toast/getError", "Cập nhật vai trò thất bại")
          this.selectedRole = null
        })
        .finally(() => {
          this.loadingUpdate = false
        })
    },
    cancelCreateData() {
      this.visibleDialogCreate = false
    },
    addedNewRole(detailRole) {
      try {
        this.getListRoles()
        this.selectedRoleId = detailRole.id || null
        this.cancelCreateData()
      } catch (error) {
        console.log(error)
        this.getListRoles()
        this.cancelCreateData()
      }
    },
  },
}
</script>

<style>
.container-empty-background {
  display: block;
  /* margin: auto; */
  max-width: 95%;
}
.content-slot-content {
  height: calc(100% - 44px);
  border-radius: 8px;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.list-roles {
  width: 420px;
  min-width: 420px;
  border: 1px solid #e5e5e5;
  height: 100%;
}
.roles-title,
.page-border-btm {
  border-bottom: 1px solid #e5e5e5 !important;
}
.role-block {
  transition: 0.2s ease;
  font-weight: 500;
  &:hover {
    background-color: rgba(13, 112, 66, 0.248);
  }
  &.role-active {
    background-color: rgba(13, 112, 66);
    color: white;
  }
}

.detail-role-name {
  font-weight: bold;
  font-size: 24px;
}
.input-border-bottom {
  border-bottom: 1px solid #000 !important;
  &:focus {
    border-bottom: 1px solid #000 !important;
  }
}
.each-app {
  min-width: 120px;
  font-weight: 500;
}
.detail-role {
  border: 1px solid #e5e5e5 !important;
  position: relative;
  &.is-loading {
    opacity: 0.7;
    pointer-events: none !important;
  }
}
.group-pms {
  height: calc(100% - 200px);
  overflow-y: scroll;
}
.update-block {
  position: absolute;
  background-color: #fff;
  padding: 16px;
  z-index: 4;
  border-top: 1px solid #e5e5e5 !important;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.detail-role-desc {
  position: sticky;
  top: 0;
}
</style>
