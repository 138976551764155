import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "@/plugins/vuetify.js"
import "@/plugins/vuetify.js"
import "vuetify/dist/vuetify.min.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "@mdi/font/css/materialdesignicons.css"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import localeMixins from "@/mixins/localeMixins.js"
import routeMixins from "@/mixins/routeMixins.js"
import Axios from "axios"
import { getCookie } from "@/helpers/customizeCookie.js"
import VueSocketIO from "vue-socket.io"
import VueDragscroll from "vue-dragscroll"
import TableFirstLoading from "./components/Loader/TableFirstLoading.vue"
import EmptyBody from "./components/Table/EmptyBody.vue"
import { syncFavicon } from "@/helpers/syncTheme"
// lg
import en from "@/plugins/locales/en.json"
import vi from "@/plugins/locales/vi.json"
Vue.prototype.$lg = {
  en: en,
  vi: vi,
}

const VUE_APP_CHAT_URL = process.env.VUE_APP_CHAT_URL || "/!@#$udfff!@#$/"
const localLToken = localStorage.getItem("token")
const localCToken = getCookie("token")
const localToken = localCToken || localLToken

Vue.config.productionTip = false
Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Axios.defaults.headers = { Authorization: `Bearer ${localToken}` }
Axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const status = error.response ? error.response.status : null
    console.log(status, error)
    if (status && status === 401) {
      const currentUser = store.getters.userFromAuth
      if (currentUser && currentUser.id) {
        store.commit("toast/getError", "Your token has expired.")
      }
      store.commit("setCurrentToken", "")
      store.commit("SET_LOGOUT_USER")
      store.commit("SET_LOGOUT_POPUP", false)
      setTimeout(() => {
        router.push("/login")
      }, 3000)
    }
    if (!status) {
      store.commit(
        "toast/getError",
        "Request failed due to no internet connection",
      )
    }
    return Promise.reject(error)
  },
)
Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const fConfig = { ...config }
    if (config.url.includes(VUE_APP_CHAT_URL)) {
      delete fConfig.headers["Authorization"]
      fConfig.headers["x-auth-token"] =
        "vUJj1geMBtC1S-VJyL7a3aBd2gpWZBuQRncolmYT8ai"
      fConfig.headers["x-user-id"] = "Wkzu6Lzj25n6HyJrj"
    }
    // fConfig.headers["Cache-Control"] = "no-cache"
    // fConfig.headers["Pragma"] = "no-cache"
    // fConfig.headers["Expires"] = "0"

    return fConfig
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_SOCKET_IO_URL,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
    options: {
      transports: ["websocket", "polling"],
    }, //Optional options
  }),
)
Vue.use(VueDragscroll)
Vue.component("TableFirstLoading", TableFirstLoading)
Vue.component("EmptyBody", EmptyBody)
syncFavicon()

Vue.mixin(objHandlerMixins)
Vue.mixin(localeMixins)
Vue.mixin(routeMixins)
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
