<template>
  <div>
    <div v-if="unique" class="file-input-holder">
      <p
        v-if="title"
        class="d-block text-md font-regular leading-6 text-gray-900 mb-2"
      >
        {{ title }}
        <span v-if="required" class="text-red-500">*</span>
      </p>
      <label
        :for="`input-file-${unique}`"
        class="order-attachments primary text-white pointer py-2 rounded-lg d-flex align-items-center gap-2 justify-center"
        ><v-icon size="18px" class="text-white"> mdi-tray-arrow-up </v-icon>
        <p class="text-white text-sm text-center font-semibold">
          Nhấn vào đây để tải file lên
        </p>
      </label>
      <input
        :id="`input-file-${unique}`"
        type="file"
        class="d-none"
        multiple
        @change="onChangeFile"
      />
    </div>
    <div class="mt-2">
      <div
        v-for="item in filesClipboard.slice(0, maxFile)"
        :key="item.id"
        class="d-flex align-items-center py-1"
      >
        <div class="d-flex align-items-center pointer gap-2 text-info-500">
          <img
            :src="item.extension ? getFileIcon(item.extension) : ''"
            height="28px"
            :alt="item.name"
          />
          <a :href="item.url" target="_blank">{{
            getShortFileName(item.original_name)
          }}</a>
        </div>
        <span class="ml-auto pointer" @click="$emit('removeLocalFile', item)">
          <v-icon size="18px" class="text-red-500"> mdi-close </v-icon>
        </span>
        <!-- <span class="ml-auto">{{ $globalHelpers.formatBytes(item.sizes || 0) }}</span> -->
      </div>
      <p
        v-if="filesClipboard.length > 3"
        class="text-info-500 flex items-center gap-2 mt-2 justify-center text-center w-25 py-2 cursor-pointer hover:bg-info-100/50 rounded mx-auto"
        @click="collapse = !collapse"
      >
        {{ collapse ? "Xem thêm" : "Thu gọn" }}
      </p>
    </div>
  </div>
</template>

<script>
import api from "@/services"
import handleFiles from "@/mixins/handleFiles.js"
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    localFileRemoved: {
      type: Array,
      default() {
        return []
      },
    },
    suffixIconName: {
      type: String,
      default: "",
    },
    initData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  mixins: [handleFiles],

  data() {
    return {
      firstUpload: false,
      filesClipboard: [],
      acceptedFileType: ["docx", "xls", "xlsx", "png", "jpg", "jpeg", "pdf"],
      fileSizeLimit: 1024 * 1024 * 5,
      unique: "",
      collapse: true,
    }
  },
  watch: {
    filesClipboard: {
      deep: true,
      handler() {
        this.$emit("on-change", [...this.filesClipboard])
      },
    },
    localFileRemoved: {
      deep: true,
      handler() {
        if (this.localFileRemoved.length) {
          //@ts-ignore
          this.filesClipboard = this.filesClipboard.filter(
            (o) => !this.localFileRemoved.includes(o.id),
          )
        }
      },
    },
  },
  computed: {
    maxFile() {
      const currentLength = this.filesClipboard.length
      return this.filesClipboard.length > 3
        ? this.collapse
          ? 3
          : currentLength
        : currentLength
    },
  },
  mounted() {
    if (Array.isArray(this.initData) && this.initData.length) {
      this.filesClipboard = [...this.initData]
    }
    this.generateUniqueInputForm()
  },
  methods: {
    getShortFileName(_str, numb = 32) {
      if (!_str) {
        return ""
      }
      if (_str.length < numb) {
        return _str
      }
      return _str.slice(0, numb) + "..." + _str.split(".").pop()
    },
    generateUniqueInputForm() {
      const num = Math.floor(Math.random() * new Date().getTime())
      if (document.querySelector(`#input-file-${num}`)) {
        this.generateUniqueInputForm()
      } else {
        this.unique = `${num}`
      }
    },
    async onChangeFile(event) {
      const target = event.target
      if (!target) {
        return
      }
      const files = target.files
      // if (files.length + this.filesClipboard.length > this.limitFiles.amount) {
      //   alert("Số lượng file quá lớn !")
      //   return
      // }
      this.firstUpload = true
      for (let file of files) {
        const fileTypeByName = file.name.split(".").pop()?.toLowerCase() || ""

        if (!this.acceptedFileType.includes(fileTypeByName)) {
          this.$store.commit(
            "toast/getError",
            `không thể upload file: ${file.name} do định dạng file không phù hợp`,
          )
        } else if (file.size > this.fileSizeLimit) {
          this.$store.commit(
            "toast/getError",

            `không thể upload file: ${
              file.name
            } do kích thước quá lớn, kích thước tối đa: ${
              this.fileSizeLimit / 1024 ** 2
            }Mb .`,
          )
        } else {
          const formData = new FormData()

          formData.append("upload_files[]", file)
          const res = await api.fileHandle.upload(formData)

          if (!res) {
            this.$store.commit(
              "toast/getError",
              this.sysLanguage.snackbar.actionFailDefault,
            )
            return
          }
          try {
            if (res.status && res.status >= 400) {
              this.$store.commit(
                "toast/getError",
                `không thể upload file: ${file.name} do: ${res.data.message}`,
              )
              this.$store.commit("toast/getError", res.data.message)
              return
            }
            this.filesClipboard = this.filesClipboard.concat([
              //@ts-ignore
              {
                ...res.data.data[0],
                sortName:
                  this.getShortFileName(res.data.data[0].original_name) ||
                  "unknown-file-name",
                name: res.data.data[0].name || res.data.data[0].original_name,
              },
            ])
          } catch (error) {
            this.$store.commit(
              "toast/getError",
              `không thể upload file: ${file.name} do: ${res.data.message}`,
            )
            this.$store.commit("toast/getError", `${error}`)
          }
        }
      }
    },
    getFileProgressClass(status_id) {
      switch (status_id) {
        case 3:
          return "file-progress--on-queue"
        case 2:
          return "file-progress--on-progress"
        case 4:
          return "file-progress--on-failed"
        case 5:
          return "file-progress--on-success"

        default:
          return ""
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.order-attachments {
  height: 42px;
}
</style>
