<template>
  <v-container class="page-container p-3">
    <UserCompanyContainer />
  </v-container>
</template>

<script>
import UserCompanyContainer from "@/components/Container/UserCompanyContainer.vue"
export default {
  components: { UserCompanyContainer },
}
</script>
